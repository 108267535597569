import * as React from 'react'
import classnames from 'classnames'
import { slider, slide} from './Slider.module.scss'

export default class Slider extends React.Component {
  state = {
    currentSlide: 1,
    leavingSlide: 0,
  }

  next() {
    this.setState({
      currentSlide: this.state.currentSlide + 1 === this.props.children.length ? 0 : this.state.currentSlide + 1,
      leavingSlide: this.state.leavingSlide + 1 === this.props.children.length ? 0 : this.state.leavingSlide + 1,
    })
  }

  render() {
    return (
      <div className={slider}>
        {this.props.children.map((child, index) => (
          <div
            key={index}
            className={classnames(slide, {
              [this.props.classes.current]: index === this.state.currentSlide,
              [this.props.classes.leaving]: index === this.state.leavingSlide,
            })}
          >
            {child}
          </div>
        ))}
      </div>
    )
  }
}
