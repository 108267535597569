import * as React from 'react'
import Hls from 'hls.js';
import ReactHlsPlayer from 'react-hls-player';
import { videoSrc } from '../../data/index'

// patched as this does not work in the build environment for some reason.
if (!Hls.isSupported) {
  Hls.isSupported = () => false;
}

export default React.memo(({ showDisclaimer, onVideoTimeUpdated }) => {
  const videoRef = React.useRef(null)
  const videoOptions = {
    src: videoSrc,
    autoPlay: false,
    muted: true,
    loop: true,
    onTimeUpdate: onVideoTimeUpdated,
    playerRef: videoRef,
    style: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }
  }
  React.useEffect(() => {
    if (!showDisclaimer && videoRef.current) {
      videoRef.current.play()
    }
  }, [showDisclaimer])

  return (
    <>
      {React.useMemo(
        () => (
          <ReactHlsPlayer {...videoOptions} />
        ),
        [],
      )}
    </>
  )
})