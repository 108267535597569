import brazilBackground from './images/brazil.jpg'
import chinaBackground from './images/china.jpg'
import turkeyBackground from './images/turkey.jpg'
import southAfricaBackground from './images/south-africa.jpg'

export const globalLocations = [
  {
    place: 'Brazil',
    lat: '51.9253° W',
    lon: '14.2350° S',
    image: brazilBackground,
  },
  {
    place: 'China',
    lon: '35.8617° N',
    lat: '104.1954° E',
    image: chinaBackground,
  },
  {
    place: 'Turkey',
    lon: '38.9637° N',
    lat: '35.2433° E',
    image: turkeyBackground,
  },
  {
    place: 'South Africa',
    lon: '30.5595° S',
    lat: '22.9375° E',
    image: southAfricaBackground,
  },
]

// EmsoWebsiteVideo_1 is 1920x1080 (largest)
export const videoSrc = 'https://tfproductionmedia.blob.core.windows.net/ams-migration-output/asset-a72dcea8-6385-432f-b912-bb9ab5ff81db/EmsoWebsiteVideo_1.m3u8'
