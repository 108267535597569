import * as React from 'react'
import { navigate } from 'gatsby'
import { closerLook, title, hover } from './CloserLook.module.scss'

class CloserLook extends React.Component {
  render() {
    return (
      <div className={closerLook} onClick={() => navigate('/approach/')}>
        <span className={title}>Take a closer look</span>
        <span className={hover}>Take a closer look</span>
      </div>
    )
  }
}

export default CloserLook
