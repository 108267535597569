import * as React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import {Waypoint} from 'react-waypoint'
import Layout from '../layouts/Layout'
import Globe from '../components/Globe/Globe'
import CloserLook from '../components/CloserLook/CloserLook'
import Slider from '../components/Slider/Slider'
import CoverVideo from '../components/CoverVideo/CoverVideo'
import { StateContext } from '../context/context'
import { globalLocations } from '../data/index'
import * as style from './index.module.scss'
// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
/* eslint-disable */
const mobilecheck = () => {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}
/* eslint-enable */

let isMobile = false

const videoBreakpoints = [
  18.05, // Brazil
  37.05, // 19.05, China
  54.6, // 18.05, Turkey
  Infinity, // 17.75, South Africa
]

export default class IndexPage extends React.Component {
  backgroundImageSliderRef = React.createRef()
  globeRef = React.createRef()
  interval = null
  currentVideoBreakPoint = 0
  lastVideoTime = 0

  state = {
    hidden: true,
  }

  UNSAFE_componentWillMount() {
    try {
      isMobile = mobilecheck()
    } catch (e) {}
    if (isMobile) {
      this.interval = setInterval(() => {
        if (this.backgroundImageSliderRef.current) {
          this.backgroundImageSliderRef.current.next()
        }
        this.globeRef.current.nextLocation()
      }, 15000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  onVideoTimeUpdated = e => {
    const currentTime = e.target.currentTime

    // Restart the loop
    if (currentTime < this.lastVideoTime) {
      this.globeRef.current.nextLocation()
      this.lastVideoTime = currentTime
      this.currentVideoBreakPoint = 0
    }

    // Progress to next location
    if (currentTime > videoBreakpoints[this.currentVideoBreakPoint]) {
      this.globeRef.current.nextLocation()
      this.lastVideoTime = currentTime
      this.currentVideoBreakPoint++
    }
  }

  onEnter() {
    this.setState({ hidden: false })
  }

  render() {
    return (
      <Layout hideLogoText={false} footerVisible={false} className={style.homePage}>
        <Helmet>
          <title>Emso Asset Management</title>
          <meta
            name="description"
            content="Emso is a specialist asset manager, seeking to deliver risk managed returns on fixed income investments in emerging markets."
          />
        </Helmet>
        <div className={classnames(style.backgroundContainer, { [style.backgroundContainerVideo]: !isMobile })}>
          {!isMobile && (
            <StateContext.Consumer>
              {({ disclaimer }) => <CoverVideo showDisclaimer={disclaimer.showDisclaimer} onVideoTimeUpdated={this.onVideoTimeUpdated} />}
            </StateContext.Consumer>
          )}
          {isMobile && (
            <Slider
              classes={{
                leaving: style.leavingImage,
                current: style.currentImage,
              }}
              ref={this.backgroundImageSliderRef}
            >
              {globalLocations.map(({ image }, index) => (
                <div key={index} className={style.mobileBackgroundImage} style={{ backgroundImage: `url(${image})` }} />
              ))}
            </Slider>
          )}
        </div>
        <Waypoint onEnter={this.onEnter.bind(this)}>
          <section className={style.headlineSection}>
            <Globe ref={this.globeRef} hidden={this.state.hidden} />
            <h1 className={this.state.hidden ? style.hiddenHeadline : style.headline}>
              Discipline, depth, <br />
              and experience.
            </h1>
            <CloserLook />
          </section>
        </Waypoint>
      </Layout>
    )
  }
}
