/* global requestAnimationFrame */
import * as React from 'react'
import PropTypes from 'prop-types'
import Slider from '../Slider/Slider'
import { globalLocations } from '../../data/index'
import {
  currentDisplayLocation,
  displayLocation,
  displayLocationLatLon,
  displayLocationPlace,
  globalLocationWrapper,
  globe,
  globeImage,
  hiddenGlobalLocationWrapper,
  leavingDisplayLocation,
  location
} from './Globe.module.scss'

export default class Globe extends React.Component {
  static propTypes = {
    hidden: PropTypes.bool.isRequired,
  }

  locationSliderRef = React.createRef()

  theFrame = 0
  animating = true
  state = {
    backgroundPosition: '',
  }

  animate() {
    this.theFrame++
    this.setState({
      backgroundPosition: -this.theFrame * 65 + 'px 0px',
    })
  }

  animationLoop() {
    if (!this.animating) return
    if (this.theFrame === 160) return
    this.animate()
    // check for gatsby build process in node
    try {
      requestAnimationFrame(this.animationLoop.bind(this))
    } catch (e) {}
  }

  UNSAFE_componentWillMount() {
    this.animating = true
    this.animationLoop()
  }

  componentWillUnmount() {
    this.animating = false
  }

  nextLocation() {
    this.locationSliderRef.current.next()
  }

  render() {
    return (
      <div className={location}>
        <div className={globe}>
          <div className={globeImage} style={{ backgroundPosition: this.state.backgroundPosition }} />
        </div>
        <div className={this.props.hidden ? hiddenGlobalLocationWrapper : globalLocationWrapper}>
          <Slider classes={{ leaving: leavingDisplayLocation, current: currentDisplayLocation }} ref={this.locationSliderRef}>
            {globalLocations.map(location => (
              <div key={location.place} className={displayLocation}>
                <h2 className={displayLocationPlace}>{location.place}</h2>
                <div className={displayLocationLatLon}>
                  <span>{`${location.lon}, ${location.lat}`}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  }
}
