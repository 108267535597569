// extracted by mini-css-extract-plugin
export var currentDisplayLocation = "emso-Globe-module__currentDisplayLocation";
export var displayLocation = "emso-Globe-module__displayLocation";
export var displayLocationLatLon = "emso-Globe-module__displayLocationLatLon";
export var displayLocationPlace = "emso-Globe-module__displayLocationPlace";
export var globalLocationWrapper = "emso-Globe-module__globalLocationWrapper";
export var globe = "emso-Globe-module__globe";
export var globeImage = "emso-Globe-module__globeImage";
export var hiddenGlobalLocationWrapper = "emso-Globe-module__hiddenGlobalLocationWrapper emso-Globe-module__globalLocationWrapper";
export var leavingDisplayLocation = "emso-Globe-module__leavingDisplayLocation";
export var location = "emso-Globe-module__location";